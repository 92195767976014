import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import getApiBaseUrl from './api';

function Dashboard() {
  const [users, setUsers] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [username, setUsername] = useState('');

  const [userId, setUserId] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [keepaliveInterval, setKeepaliveInterval] = useState(60);
  const [gracePeriod, setGracePeriod] = useState(60);

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    } else {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUsername(decodedToken.customerId);
      fetchUsers();
    }
  }, [history]);

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    try {
      await axios.post(`${getApiBaseUrl()}/logout`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    }
    catch (_) {}
    window.location.href = '/login';
  }

  const genericErrorHandler = (error, alertMessage) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      alert('Unauthorized. Please login again.');
      history.push('/login');
    }
    else {
      if (error.response && error.response.data.message) {
        alertMessage += "\n\n" + error.response.data.message;
      }
      alert(alertMessage);
    }
  }

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${getApiBaseUrl()}/customer/users`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setUsers(response.data);
      console.log("usrs:", response.data)
    } catch (error) {
      genericErrorHandler(error, 'Failed to fetch users.')
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${getApiBaseUrl()}/customer/users`, {
        userId, 
        capacity,
        keepaliveInterval,
        gracePeriod
      }, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      // setShowAddUser(false);

      setUserId('');
      setCapacity(0);
      setKeepaliveInterval(60);
      setGracePeriod(60);
      setAccessToken(response.data.accessToken);

      await fetchUsers().catch(()=>{});
    } catch (error) {
      setShowAddUser(false);
      genericErrorHandler(error, 'Failed to add user.')
    }
  };

  const handleRemoveUser = async (e, userId) => {
    e.preventDefault();
    try {
      await axios.delete(`${getApiBaseUrl()}/customer/users/${userId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      await fetchUsers().catch(()=>{});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove user.')
    }
  }

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-secondary" onClick={handleLogout}>Logout</button>
        <h2>Welcome, {username}</h2>
        <button className={`btn btn-primary ${showAddUser ? 'invisible' : ''}`} onClick={() => setShowAddUser(!showAddUser)}>
          Add User
        </button>
      </div>
      {showAddUser && (
        <div className="bg-light p-3 mb-4">
          <h4 className="mb-3 text-left">New User</h4>
          {accessToken ? (
            <div className="mt-3">
              <h5>Access Token:</h5>
              <p style={{ fontFamily: "monospace", wordWrap: "break-word" }}>{accessToken}</p>
              <button className="btn btn-primary btn-sm" onClick={() => {
                setAccessToken('')
                setShowAddUser(false)
              }}>Close</button>
            </div>
          ) : (
            <form onSubmit={handleAddUser}>
              <div className="row g-3">
                <div className="col-md-6">
                  <label htmlFor="userId" className="form-label">User ID</label>
                  <input type="text" className="form-control" id="userId" placeholder="github-job-1" value={userId} onChange={(e) => setUserId(e.target.value)} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="capacity" className="form-label">Capacity</label>
                  <input type="number" className="form-control" id="capacity" placeholder="0" value={capacity} onChange={(e) => setCapacity(parseInt(e.target.value) || 0)} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="keepaliveInterval" className="form-label">Keepalive interval</label>
                  <input type="number" className="form-control" id="keepaliveInterval" placeholder="60" value={keepaliveInterval} onChange={(e) => setKeepaliveInterval(parseInt(e.target.value) || 0)} />
                </div>
                <div className="col-md-6">
                  <label htmlFor="gracePeriod" className="form-label">Grace Period</label>
                  <input type="number" className="form-control" id="gracePeriod" placeholder="60" value={gracePeriod} onChange={(e) => setGracePeriod(parseInt(e.target.value) || 0)} />
                </div>
                <div className="col-12">
                  <button type="button" className="btn btn-secondary me-2" onClick={() => setShowAddUser(false)}>Cancel</button>
                  <button type="submit" className="btn btn-success">Add New</button>
                </div>
              </div>
            </form>
          )}
        </div>
      
      )}
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">User ID</th>
            <th scope="col">Capacity</th>
            <th scope="col">Keepalive interval</th>
            <th scope="col">Grace Period</th>
            <th scope="col" className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.userId}</td>
              <td>{user.capacity === 0? 'max': user.capacity}</td>
              <td>{user.keepaliveInterval}</td>
              <td>{user.gracePeriod}</td>
              <td className="text-end">
                <button className="btn btn-danger btn-sm" onClick={(e) => handleRemoveUser(e, user.userId)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Dashboard;
